<template>
  <div class="p-20px">
    <el-card class="event-card">
      <template #header>
        <div class="event-header">
          <h2>{{ $t('events.session') }}</h2>
          <div>
            <span class="fas fa-check-circle color-success event-icon" />
            <span class="color-success event-text">{{ $tc('general.success-count', successEvents.length, { count: successEvents.length }) }}</span>|
            <span class="fas fa-exclamation-triangle color-warning event-icon" />
            <span class="color-warning event-text">{{ $tc('general.warning-count', warningEvents.length, { count: warningEvents.length }) }}</span>|
            <span class="fas fa-times-octagon color-danger event-icon" />
            <span class="color-danger event-text">{{ $tc('general.error-count', errorEvents.length, { count: errorEvents.length }) }}</span>
          </div>
        </div>
      </template>
      <template v-for="item in allEvents">
        <p :key="item.hid + 'time'"
          class="m-0 text-align-right p-0">
          {{ item.time }}
        </p>
        <el-alert :key="item.hid"
          :title="item.title"
          :type="item.type"
          :description="item.message"
          :closable="false"
          class="mb-1"
          show-icon />
      </template>
      <hr class="my-20px mx-0">
      <div class="text-align-center">
        <el-button type="primary"
          @click="clearLog">
          {{ $t('events.clear') }}
        </el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  computed: {
    /**
     * Get the event information and split it into the different types.
     */
    allEvents() {
      return this.$store.getters['Notifications/reversedEventLog']
    },
    successEvents() {
      return this.allEvents.filter(event => {
        return event.type === 'success'
      })
    },
    warningEvents() {
      return this.allEvents.filter(event => {
        return event.type === 'warning'
      })
    },
    errorEvents() {
      return this.allEvents.filter(event => {
        return event.type === 'error'
      })
    },
  },

  methods: {
    clearLog() {
      this.$store.commit('Notifications/clearEventLog')
      const props = {
        title: this.$t('menu.event-log'),
        message: this.$t('events.cleared'),
        type: 'success',
      }
      this.$notify(props)
    },
  },
}
</script>

<style lang="scss" scoped>
.event {
  &-card {
    max-width: 700px;
    margin: auto;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    div {
      margin: auto 0;
    }
  }
  &-text {
    padding: 0 5px 0 3px;
  }
}

.my-20px {
  margin-block: 20px;
}
</style>
